import { Link } from 'gatsby'
import React, { useEffect, useState } from 'react'

function CookieAgreement() {
  const COOKIE_IDENTIFIER = 'accepted-cookies'
  const [showAgreement, setShowAgreement] = useState(false)
  const [debouncedShowAgreement, setDebouncedShowAgreement] = useState(false)

  useEffect(() => {
    const acceptedCookies = localStorage.getItem(COOKIE_IDENTIFIER) || false

    if (!acceptedCookies) {
      setShowAgreement(true)
      setDebouncedShowAgreement(true)
    }
  }, [])

  return (
    debouncedShowAgreement && (
      <div
        className="notification is-link fixed-bottom br-0 horizontal-list is-flex is-vcentered is-hcentered fd-r"
        style={{ opacity: showAgreement ? 1 : 0, zIndex: 99999 }}
        data-testid={1}
      >
        <p>
          By using our website you are agreeing to the use of cookies. Please
          ready our <Link to="/privacy-policy/">Privacy Policy</Link> for more
          info.
        </p>
        <button
          type="button"
          tabIndex="-1"
          className="button is-rounded is-dark semibold"
          onClick={e => {
            e.preventDefault()
            setShowAgreement(false)
            setTimeout(() => {
              setDebouncedShowAgreement(false)
            }, 300)
            localStorage.setItem(COOKIE_IDENTIFIER, true)
          }}
        >
          Dismiss
        </button>
      </div>
    )
  )
}

export default CookieAgreement
