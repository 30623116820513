import { graphql, StaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import Helmet from 'react-helmet'
import { decodeHtml } from '../utils/helpers'
import SchemaOrg from './SchemaOrg'

const SEO = ({
  postData,
  postImage,
  isBlogPost = false,
  isLandingPage = false,
  pageTitle,
  pageDescription,
}) => (
  <StaticQuery
    query={graphql`
      {
        site {
          siteMetadata {
            title
            description
            image
            siteUrl
            organization {
              name
              url
              logo
            }
            social {
              twitter
              fbAppID
            }
          }
        }
      }
    `}
    render={({ site: { siteMetadata: seo } }) => {
      const postMeta = postData || {}
      let title = decodeHtml(
        isBlogPost ? postMeta.title : pageTitle || seo.title
      )
      const description =
        postMeta.description || pageDescription || seo.description
      const image = postImage ? `${seo.siteUrl}${postImage}` : seo.image
      const url = postMeta.slug
        ? `${seo.siteUrl}/${postMeta.slug}`
        : seo.siteUrl
      const datePublished = isBlogPost ? postMeta.date : false

      if (!isLandingPage) {
        title += ' | DFS'
      }

      return (
        <React.Fragment>
          <SchemaOrg
            isBlogPost={isBlogPost}
            url={url}
            title={title}
            image={image}
            description={description}
            datePublished={datePublished}
            siteUrl={seo.siteUrl}
            organization={seo.organization}
            defaultTitle={seo.title}
          />
          <Helmet>
            {/* General tags */}
            <title>{title}</title>
            <html lang="en" />
            <meta name="description" content={description} />
            <meta name="image" content={image} />
            <meta name="robots" content="index, follow" />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0"
            />

            {/* OpenGraph tags */}
            <meta property="og:url" content={url} />
            {isBlogPost ? <meta property="og:type" content="article" /> : null}
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={image} />
            <meta property="og:site_name" content={seo.title} />
            <meta property="fb:app_id" content={seo.social.fbAppID} />

            {/* Twitter Card tags */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:creator" content={seo.social.twitter} />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={image} />
          </Helmet>
        </React.Fragment>
      )
    }}
  />
)

SEO.propTypes = {
  isBlogPost: PropTypes.bool,
  isLandingPage: PropTypes.bool,
  postData: PropTypes.shape({
    acf: PropTypes.object,
    categories: PropTypes.array,
    content: PropTypes.string,
    date: PropTypes.string,
    id: PropTypes.string,
    slug: PropTypes.string,
    tags: PropTypes.array,
    title: PropTypes.string,
  }),
  postImage: PropTypes.string,
  pageTitle: PropTypes.string,
  pageDescription: PropTypes.string,
}

SEO.defaultProps = {
  isBlogPost: false,
  postData: {},
  postImage: null,
  pageTitle: '',
  pageDescription: '',
}

export default SEO
