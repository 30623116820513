// import { useMsal } from '@azure/msal-react'
import { graphql, Link, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import chunk from 'lodash/chunk'
import React from 'react'
import { Facebook, Linkedin, Mail, MapPin, Phone, Twitter } from 'react-feather'
import { extractPathFromWordpressUrl } from '../utils/helpers'

const Footer = () => (
  <StaticQuery
    query={graphql`
      query {
        allWordpressAcfOptions {
          edges {
            node {
              options {
                solutions {
                  id: uID
                  name
                  isFeatured
                  featuredPage
                }
                footer {
                  icon {
                    localFile {
                      childImageSharp {
                        fluid(quality: 100) {
                          ...GatsbyImageSharpFluid_noBase64
                        }
                      }
                    }
                    alt_text
                  }
                  socialMedia {
                    twitter
                    linkedin
                    facebook
                  }
                  contactInfo {
                    location
                    phoneNumber
                    email
                  }
                }
              }
            }
          }
        }
        wordpressWpApiMenusMenusItems(slug: { eq: "footer-menu" }) {
          items {
            ...MenuFields
            wordpress_children {
              ...MenuChildrenFields
            }
          }
        }
        allWordpressPost(
          sort: { fields: date, order: DESC }
          filter: { acf: { isFeatured: { eq: true } } }
          limit: 3
        ) {
          edges {
            node {
              ...PostFields
            }
          }
        }
      }
    `}
    render={data => {
      // const { instance } = useMsal()
      const {
        icon: {
          localFile: {
            childImageSharp: { fluid },
          },
          alt_text: altText,
        },
        contactInfo: { location, phoneNumber, email },
        socialMedia: { twitter, linkedin, facebook },
      } = data.allWordpressAcfOptions.edges[0].node.options.footer
      const { solutions } = data.allWordpressAcfOptions.edges[0].node.options
      const { items: footerMenuItems } = data.wordpressWpApiMenusMenusItems
      const featuredSolutions = solutions.filter(
        solution => solution.isFeatured
      )
      featuredSolutions.length = 3

      const handleLogin = () => {
        instance.loginRedirect()
      }

      const handleLogout = () => {
        instance.logoutRedirect()
      }

      return (
        <div role="contentinfo">
          <div className="footer">
            <div className="container">
              <div className="columns is-variable is-1-mobile is-2-tablet is-3-desktop is-8-widescreen is-2-fullhd">
                <div className="column">
                  <figure className="figure mb-4">
                    <Img fluid={fluid} alt={altText} />
                  </figure>
                  <ul className="info">
                    <li className="info-item is-vcentered-row">
                      <span className="footer-icon">
                        <MapPin />
                      </span>
                      <span>
                        <a
                          href="https://www.google.com/maps/place/401+Broad+St+%23206,+Rome,+GA+30161/@34.2550972,-85.1720624,19.33z/data=!4m5!3m4!1s0x888aa4befb2336d7:0x192d6558b685a43!8m2!3d34.254994!4d-85.1717119"
                          target="_blank"
                          title="Headquarters location"
                          aria-label="Location"
                          aria-labelledby=""
                          rel="noopener noreferrer"
                          className="with-underline"
                        >
                          {location}
                        </a>
                      </span>
                    </li>
                    <li className="info-item is-vcentered-row">
                      <span className="footer-icon">
                        <Phone />
                      </span>
                      <span>{phoneNumber}</span>
                    </li>
                    <li className="info-item is-vcentered-row">
                      <span className="footer-icon">
                        <Mail />
                      </span>
                      <span>
                        <a
                          href={`mailto:${email}`}
                          className="with-underline"
                          title="Phone Number"
                          aria-label="Phone Number"
                          aria-labelledby=""
                        >
                          {email}
                        </a>
                      </span>
                    </li>
                  </ul>
                  <h4 className="subtitle mb-4 has-text-dark">
                    Stay Connected
                  </h4>
                  <div className="social-media-container">
                    <a
                      href={twitter}
                      className="social-media"
                      title="Twitter link"
                      aria-label="Twitter"
                      aria-labelledby=""
                    >
                      <span className="icon is-medium social-media-icon">
                        <Twitter />
                      </span>
                    </a>
                    <a
                      href={linkedin}
                      className="social-media"
                      title="LinkedIn link"
                      aria-label="LinkedIn"
                      aria-labelledby=""
                    >
                      <span className="icon is-medium social-media-icon">
                        <Linkedin />
                      </span>
                    </a>
                    <a
                      href={facebook}
                      className="social-media"
                      title="Facebook link"
                      aria-label="Facebook"
                      aria-labelledby=""
                    >
                      <span className="icon is-medium social-media-icon">
                        <Facebook />
                      </span>
                    </a>
                  </div>
                </div>
                <div className="column">
                  <div className="columns is-variable is-1-mobile is-2-tablet is-3-desktop is-8-widescreen is-2-fullhd">
                    {chunk(footerMenuItems, 3).map((row, idx) => (
                      <div
                        className="column vertical-list"
                        key={row[idx].wordpress_id}
                      >
                        {row.map(obj => (
                          <div key={obj.wordpress_id}>
                            <Link
                              to={extractPathFromWordpressUrl(obj.url)}
                              className="has-text-dark"
                              aria-label={obj.title}
                              aira-labelledby=""
                            >
                              <p className="semibold route-title is-size-5">
                                {obj.title}
                              </p>
                            </Link>
                            <div className="children">
                              {obj.wordpress_children &&
                                obj.wordpress_children.length > 0 &&
                                obj.wordpress_children.map(child => (
                                  <Link
                                    to={extractPathFromWordpressUrl(child.url)}
                                    key={child.wordpress_id}
                                    className="has-text-dark"
                                    aria-label={child.title}
                                    aira-labelledby=""
                                  >
                                    <p className="is-size-6 child">
                                      {child.title}
                                    </p>
                                  </Link>
                                ))}
                            </div>
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="column is-hidden-mobile">
                  <h4 className="title mb-8 has-text-dark">
                    Featured Solutions
                  </h4>
                  {featuredSolutions.length > 0 ? (
                    featuredSolutions.map((solution, idx) => (
                      <div className="footer-list" key={solution.id}>
                        <Link
                          to={extractPathFromWordpressUrl(
                            solution.featuredPage
                          )}
                          className="has-text-dark with-underline"
                        >
                          <p className="has-text-dark">{solution.name}</p>
                        </Link>
                        {idx !== featuredSolutions.length - 1 && <hr />}
                      </div>
                    ))
                  ) : (
                    <p>None</p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="container">
              <div
                className="space-between ptb-16"
                style={{
                  backgroundColor: '#fafafa',
                }}
              >
                <p className="footer-bottom-text mx-1">
                  Copyright © {new Date().getFullYear()} Data Fusion
                  Specialists, All rights reserved.
                </p>
                <div className="horizontal-list is-flex fd-r">
                  {/* <UnauthenticatedTemplate>
                    <a
                      role="button"
                      className="footer-bottom-text with-underline"
                      onClick={handleLogin}
                    >
                      Login
                    </a>
                  </UnauthenticatedTemplate>
                  <AuthenticatedTemplate>
                    <Link
                      to="/chat"
                      className="footer-bottom-text with-underline"
                    >
                      Chat
                    </Link>
                    <p className="is-marginless footer-bottom-text">|</p>
                    <a
                      role="button"
                      className="footer-bottom-text with-underline"
                      onClick={handleLogout}
                    >
                      Logout
                    </a>
                  </AuthenticatedTemplate> */}
                  {/* <p className="is-marginless footer-bottom-text">|</p> */}
                  <Link
                    to="/privacy-policy/"
                    className="footer-bottom-text with-underline"
                  >
                    Privacy Policy
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }}
  />
)

export default Footer
