import { useEffect, useRef, useState } from 'react'

// TODO: document these
// TODO: test these

let hasPassiveSupport
const getHasPassiveEventSupport = () => {
  if (typeof hasPassiveSupport === 'boolean') {
    return hasPassiveSupport
  }

  hasPassiveSupport = false

  try {
    const opts = Object.defineProperty({}, 'passive', {
      get() {
        hasPassiveSupport = true
        return hasPassiveSupport
      },
    })

    if (typeof window !== 'undefined')
      window.addEventListener('test', null, opts)
  } catch (e) {
    hasPassiveSupport = false
  }

  return hasPassiveSupport
}

export function useScrollPosition() {
  const [scroll, setScroll] = useState({ x: 0, y: 0 })
  const tickingRef = useRef()

  const handleScroll = () => {
    setScroll({
      x: typeof window !== 'undefined' && window.pageXOffset,
      y: typeof window !== 'undefined' && window.pageYOffset,
    })
    tickingRef.current = false
  }

  const onScroll = () => {
    if (tickingRef.current) {
      return
    }

    tickingRef.current = true
    if (typeof window !== 'undefined')
      window.requestAnimationFrame(handleScroll)
  }

  useEffect(() => {
    if (typeof window !== 'undefined')
      window.addEventListener(
        'scroll',
        onScroll,
        getHasPassiveEventSupport() ? { passive: true } : false
      )

    return () => {
      if (typeof window !== 'undefined')
        window.removeEventListener('scroll', onScroll)
    }
  }, [])

  return scroll.y
}

function getSize() {
  return {
    innerHeight: typeof window !== 'undefined' && window.innerHeight,
    innerWidth: typeof window !== 'undefined' && window.innerWidth,
    outerHeight: typeof window !== 'undefined' && window.outerHeight,
    outerWidth: typeof window !== 'undefined' && window.outerWidth,
  }
}

export function useWindowSize() {
  const [windowSize, setWindowSize] = useState(getSize())

  function handleResize() {
    setWindowSize(getSize())
  }

  useEffect(() => {
    if (typeof window !== 'undefined')
      window.addEventListener('resize', handleResize)
    return () => {
      if (typeof window !== 'undefined')
        window.removeEventListener('resize', handleResize)
    }
  }, [])

  return windowSize.innerWidth
}

export function useOnClickOutside(ref, handler) {
  useEffect(() => {
    const listener = event => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return
      }

      handler(event)
    }

    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)

    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [ref, handler])
}

export function useInterval(callback, delay) {
  const savedCallback = useRef()

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      const id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

/**
 * Mimics useState but persists it by adding variables to the window URL.
 * This effect can be used multiple times in the same component. It will concat all variables together with the '&' char in the URL.
 *
 *
 * @param {String | Number} initialState initial state
 * @param {string} identifier used in url to identify the search parameter (for setting and getting)
 * @returns {array} array of `state`, `setState`, and `clearState` functions in that order
 *
 * Example:
 * ```js
 * // identifier is category
 * const [searchCategory, setSearchCategory, clearSearchCategory] = useUrlState("", "category")
 *
 * // url: example.com/search?category=myState
 * ```
 */
export function useUrlState(initialState, identifier) {
  // used in setTimeout for debounced state
  const DEBOUNCE_TIME = 200

  const [state, setState] = useState(() => {
    // get the initial state if it exists in the URL
    if (typeof window === 'undefined') {
      return initialState
    }
    const parsedUrl = new URL(window.location.href)
    const variable = parsedUrl.searchParams.get(identifier)

    if (variable && typeof initialState === 'number') {
      return parseInt(variable, 10)
    }
    if (variable) {
      return variable
    }
    // if no url state return specified initial state
    return initialState
  })
  const [debouncedState, setDebouncedState] = useState(null)

  useEffect(() => {
    if (typeof window !== 'undefined' && 'URLSearchParams' in window) {
      const searchParams = new URLSearchParams(window.location.search)

      if (String(state) === searchParams.get(identifier)) {
        return
      }

      if (
        (typeof state === 'string' && state.length > 0) ||
        typeof state === 'number'
      ) {
        clearTimeout(debouncedState)

        searchParams.set(identifier, state)
        const newRelativePathQuery =
          typeof window !== 'undefined' &&
          `${window.location.pathname}${
            searchParams.toString().length ? '?' : ''
          }${searchParams.toString()}`
        setDebouncedState(
          setTimeout(() => {
            history.pushState(null, '', newRelativePathQuery)
          }, DEBOUNCE_TIME)
        )
      } else {
        clearTimeout(debouncedState)
        searchParams.delete(identifier)
        const newRelativePathQuery =
          typeof window !== 'undefined' &&
          `${window.location.pathname}${
            searchParams.toString().length ? '?' : ''
          }${searchParams.toString()}`
        setDebouncedState(
          setTimeout(() => {
            history.pushState(null, '', newRelativePathQuery)
          }, DEBOUNCE_TIME)
        )
      }
    }
  }, [state])

  const clearState = () => {
    clearTimeout(debouncedState)
    const searchParams = new URLSearchParams(
      typeof window !== 'undefined' && window.location.search
    )
    searchParams.delete(identifier)
    const newRelativePathQuery =
      typeof window !== 'undefined' &&
      `${window.location.pathname}${
        searchParams.toString().length ? '?' : ''
      }${searchParams.toString()}`
    setDebouncedState(
      setTimeout(() => {
        history.pushState(null, '', newRelativePathQuery)
      }, DEBOUNCE_TIME)
    )

    setState(initialState)
  }

  return [state, setState, clearState]
}

/**
 * Mocks useState and persists the data in local storage
 * @param {String} key Key to be used in local storage. Must be unique.
 * @param {any} initialValue Initial value of state
 */
export function useLocalStorage(key, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      // If window doesn't exist then we are building the site on the server
      if (typeof window === 'undefined') {
        return initialValue
      }
      const item = window.localStorage.getItem(key)
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue
    } catch (error) {
      // If error also return initialValue
      console.error(error)
      return initialValue
    }
  })

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = value => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value
      // Save state
      setStoredValue(valueToStore)
      // Save to local storage
      if (typeof window !== 'undefined') {
        window.localStorage.setItem(key, JSON.stringify(valueToStore))
      }
    } catch (error) {
      console.error(error)
      return value
    }
  }

  return [storedValue, setValue]
}
