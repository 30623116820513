import BackgroundImage from 'gatsby-background-image'
import PropTypes from 'prop-types'
import React from 'react'

const FeaturedMedia = ({
  children,
  featuredMedia,
  wordpressMedia,
  className = 'full-width-image-container',
}) => {
  return featuredMedia && featuredMedia.localFile ? (
    <BackgroundImage
      fluid={featuredMedia.localFile.childImageSharp.fluid}
      className={className}
    >
      <div className={`${className}-dark`}>{children}</div>
    </BackgroundImage>
  ) : wordpressMedia ? (
    <div
      style={{
        backgroundImage: `url(${wordpressMedia.src})`,
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
      className={className}
    >
      <div className={`${className}-dark`}>{children}</div>
    </div>
  ) : (
    children
  )
}

FeaturedMedia.propTypes = {
  featuredMedia: PropTypes.shape({
    localFile: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.object.isRequired,
      }),
    }),
  }),
}

export default FeaturedMedia
