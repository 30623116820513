import { Location } from '@reach/router'
import isArray from 'lodash/isArray'
import queryString from 'query-string'
import React from 'react'

/**
 * Truncates a given string to n length and concatenates ellipses to the end.
 *
 * @param {string} string given string
 * @param {number} n length to truncate to
 * @returns {string} new string with truncated ending
 */
function trunc(string, n) {
  return string.length > n ? `${string.substr(0, n - 1)}...` : string
}

/**
 * Takes in a string of html and strips the tags off of it
 * @param {string} html html string
 * @returns {string} content from html
 */
function stripHTML(html) {
  const doc =
    typeof window != 'undefined' &&
    new DOMParser().parseFromString(html, 'text/html')

  if (!doc) {
    return ''
  }
  return doc.body.textContent || ''
}

/**
 * Takes in an encoded html string and decode it giving the pure string value
 */
function decodeHtml(html) {
  if (typeof window === 'undefined' || !document) {
    return html
  }
  const txt = document.createElement('textarea')
  txt.innerHTML = html
  return txt.value
}

/**
 * Takes a number of bytes in integer format and converts it to a string based on the size
 * @param {number} bytes number of bytes
 * @param {number=} decimals number of decimals
 */
function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

/**
 * Noramlizes the url passed in by getting rid of trailing slashes and removing the protocol (http or https)
 * @param {String} baseUrl Url to be normalized
 * @return {String} Normalized url
 */
function normalizeBaseUrl(baseUrl) {
  let normalized = baseUrl

  // remove trailing slashes
  normalized = normalized.replace(/\/+$/, ``)

  // remove protocol
  normalized = normalized.replace(/^https?:\/\//, ``)

  return normalized
}

/**
 * Extracts relative path from wordpress link by removing the url and returning the path
 * @param {string} url url to split
 * @return {string} Url path extension
 */
const extractPathFromWordpressUrl = url => {
  const array = normalizeBaseUrl(url)
    .split('/')
    .filter(st => st.length > 0)

  array.shift()
  return `/${array.join('/')}/`
}

const getMasonryLayout = numOfColumns => {
  const arr = []
  for (let i = 0; i < numOfColumns; i += 1) {
    arr.push([])
  }
  return arr
}

/**
 * @param {Number} numOfColumns number of columns. Default = 3
 * @param {Array<T>} postList
 * @return {Array<Array<T>>} 2d masonry array
 */
function transformDataToMasonryLayout(numOfColumns = 3, postList) {
  const masonryPosts = getMasonryLayout(numOfColumns)
  let i = 0

  if (postList && isArray(postList)) {
    postList.forEach(post => {
      masonryPosts[i].push(post)
      i += 1
      i %= numOfColumns // wrap around
    })
  }
  return masonryPosts
}

/**
 * Higher-order component that attaches the route location to the wrapped component
 * @param {React.Component} ComponentToWrap React component
 */
const withLocation = ComponentToWrap => props => (
  <Location>
    {({ location, navigate }) => (
      <ComponentToWrap
        {...props}
        location={location}
        navigate={navigate}
        search={location.search ? queryString.parse(location.search) : {}}
      />
    )}
  </Location>
)

/**
 * Returns true if the image is a type of Gatsby image and false otherwise
 * @param {object} image object of image to analyze
 * @return boolean
 */
const isGatsbyImage = image =>
  !!(image.localFile && image.localFile.childImageSharp)

export {
  trunc,
  stripHTML,
  formatBytes,
  extractPathFromWordpressUrl,
  transformDataToMasonryLayout,
  withLocation,
  isGatsbyImage,
  decodeHtml,
}
