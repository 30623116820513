import { graphql, Link, navigate, StaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { ArrowRight, ChevronDown, ChevronUp, Search, X } from 'react-feather'
import logo from '../img/logo.svg'
import { extractPathFromWordpressUrl } from '../utils/helpers'
import {
  useLocalStorage,
  useOnClickOutside,
  useScrollPosition,
  useWindowSize,
} from '../utils/hooks'

function renderChildren(obj, lightText, isShrunk, isActive, isMega, solutions) {
  return (
    obj.wordpress_children &&
    obj.wordpress_children.length > 0 &&
    obj.wordpress_children.map((node, index) => (
      <ChildNavbarItem
        key={node.wordpress_id}
        node={node}
        index={index}
        lightText={lightText}
        isShrunk={isShrunk}
        isActive={isActive}
        isMega={isMega}
        solutions={solutions}
      />
    ))
  )
}

function TopLevelNavbarItem({
  node,
  lightText,
  isShrunk,
  isActive,
  isMega,
  solutions,
  megaTitle = 'Featured Solutions',
}) {
  const windowWidth = useWindowSize()
  const [isCollapsed, setIsCollapsed] = useState(windowWidth < 959)
  const [useLightText, setUseLightText] = useState(false)

  useEffect(() => {
    if (windowWidth < 960) {
      setUseLightText(false)
    } else {
      setUseLightText(true)
    }
  }, [windowWidth])

  return (
    <React.Fragment>
      <div
        key={node.slug}
        className="navbar-item is-mega is-arrowless space-between navbar-item-bold"
      >
        <Link
          to={node.path ? node.path : extractPathFromWordpressUrl(node.url)}
          style={{
            color: useLightText && lightText && !isShrunk ? 'white' : '#363636',
          }}
          title={node.title}
        >
          {node.title}
        </Link>
        {node.wordpress_children && (
          <button
            type="button"
            tabIndex="-1"
            className="button navbar-menu-toggle-expand"
            style={{
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onClick={e => {
              e.preventDefault()
              setIsCollapsed(!isCollapsed)
            }}
          >
            {isCollapsed ? <ChevronDown /> : <ChevronUp />}
          </button>
        )}
      </div>
      {!isCollapsed && node.wordpress_children && (
        <div className="navbar-dropdown is-boxed" style={{ maxWidth: '100%' }}>
          {isMega && megaTitle && (
            <div className="navbar-item is-child space-between">
              <p className="is-marginless bold is-size-5">{megaTitle}</p>
              <a
                className="is-vcentered is-hcentered fd-r dark-link"
                href="/solutions/"
              >
                <span>View All</span>
                <ArrowRight className="ml-4" size={16} />
              </a>
            </div>
          )}
          <div
            className={
              isMega
                ? 'navbar-item is-child grid grid-cols-1 md:grid-cols-3 gap-2'
                : ''
            }
          >
            {renderChildren(
              node,
              lightText,
              isShrunk,
              isActive,
              isMega,
              solutions
            )}
          </div>
          {/* {isMega && megaTitle && (
            <>
              <div className="navbar-item is-child mt-2">
                <div className="is-flex fd-r">
                  <a
                    href="/media/whitepaper-dfs-jumpstart-to-ai-for-healthcare-payers/"
                    className="is-marginless semibold dark-link"
                    aria-label="DFS Jumpstart to AI for Healthcare Payers"
                  >
                    DFS Jumpstart to AI for Healthcare Payers
                  </a>
                  <span className="tag is-small is-dark ml-4 bold">
                    Whitepaper
                  </span>
                </div>
              </div>
            </>
          )} */}
        </div>
      )}
    </React.Fragment>
  )
}

const GRADIENTS = ['red-orange', 'orange-yellow', 'darkgrey-grey']

function ChildNavbarItem({ node, isMega, solutions, index }) {
  const windowSize = useWindowSize()
  const [isCollapsed, setIsCollapsed] = useState(windowSize < 959)
  const solution = solutions.find(
    s =>
      extractPathFromWordpressUrl(s.featuredPage) ===
      extractPathFromWordpressUrl(node.url)
  )
  const [isHovering, setIsHovering] = useState(false)

  const toggleHover = () => {
    setIsHovering(!isHovering)
  }

  return (
    <>
      <div
        key={node.wordpress_id}
        className={`${isMega ? 'navbar-item-mega' : 'navbar-item is-child'} ${
          node.wordpress_children && node.wordpress_children.length > 0
            ? 'navbar-item-bold'
            : ''
        } ${
          isHovering && isMega
            ? `has-gradient-${GRADIENTS[index % GRADIENTS.length]}`
            : ''
        }`}
        onMouseEnter={toggleHover}
        onMouseLeave={toggleHover}
      >
        {isMega && solution ? (
          <a
            className="navbar-item-mega-link"
            href={extractPathFromWordpressUrl(solution.featuredPage)}
            style={{ maxWidth: '100%' }}
          >
            <span className="semibold m-0">
              {solution.name || solution.title}
            </span>
            {solution.isNew && (
              <span className="tag is-small is-primary ml-4 bold">New!</span>
            )}
            {windowSize > 959 && (
              <p
                className="description-small"
                style={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                }}
              >
                {solution.description}
              </p>
            )}
          </a>
        ) : (
          <Link to={extractPathFromWordpressUrl(node.url)} title={node.title}>
            <span className="small dark-link">{node.title}</span>
          </Link>
        )}
        {node.wordpress_children && node.wordpress_children.length > 0 && (
          <button
            type="button"
            className="collapse-btn"
            onClick={e => {
              e.preventDefault()
              setIsCollapsed(!isCollapsed)
            }}
          >
            {isCollapsed ? (
              <ChevronDown className="has-text-dark" />
            ) : (
              <ChevronUp className="has-text-dark" />
            )}
          </button>
        )}
      </div>
      {!isCollapsed &&
        node.wordpress_children &&
        node.wordpress_children.length > 0 &&
        renderChildren(node, windowSize)}
    </>
  )
}

const Navbar = ({ lightText, containerStyles }) => {
  const scrollPosition = useScrollPosition()
  const windowWidth = useWindowSize()
  const searchBoxRef = useRef()
  const [isActive, setIsActive] = useState(false)
  const [isShrunk, setIsShrunk] = useLocalStorage(
    'navbarShrunk',
    scrollPosition > 82
  )
  const [isSearchOpen, setIsSearchOpen] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [useLightText, setUseLightText] = useState(true)

  useOnClickOutside(searchBoxRef, () => setIsSearchOpen(false))

  const onSearchChange = e => {
    setSearchQuery(e.target.value)
  }

  const onSearch = (e, index) => {
    e.preventDefault()
    if (searchQuery.length > 0) {
      navigate(`/search/?query=${searchQuery}${index ? `&index=${index}` : ''}`)
    }
  }

  const onEnterKeydown = e => {
    if (e.keyCode === 13) {
      onSearch()
    }
  }

  useEffect(() => {
    if (scrollPosition > 82) {
      setIsShrunk(true)
    } else {
      setIsShrunk(false)
    }
  }, [scrollPosition])

  useEffect(() => {
    if (windowWidth < 960) {
      setUseLightText(false)
    } else {
      setUseLightText(true)
    }
  }, [windowWidth])

  return (
    <StaticQuery
      query={graphql`
        fragment MenuFields on wordpress__wp_api_menus_menus_itemsItems {
          title
          object_slug
          url
          order
          wordpress_id
        }
        fragment MenuChildrenFields on wordpress__wp_api_menus_menus_itemsItemsWordpress_children {
          title
          object_slug
          url
          order
          wordpress_id
        }
        query {
          wordpressWpApiMenusMenusItems(slug: { eq: "main-menu" }) {
            items {
              ...MenuFields
              wordpress_children {
                ...MenuChildrenFields
              }
            }
          }
          acf: wordpressAcfOptions(
            id: { eq: "ef2fa151-f884-5587-9b9b-28169711bc85" }
          ) {
            options {
              solutions {
                ...Solution
              }
            }
          }
        }
      `}
      render={({
        wordpressWpApiMenusMenusItems: { items },
        acf: {
          options: { solutions },
        },
      }) => (
        <nav
          className={
            isShrunk
              ? 'navbar shrink'
              : lightText
              ? 'navbar is-transparent has-bg-gradient'
              : 'navbar is-transparent'
          }
          role="navigation"
          aria-label="navigation"
          style={scrollPosition > 5 ? {} : containerStyles}
        >
          <div className="container">
            <div className="navbar-brand">
              <Link to="/" className="navbar-item">
                <img src={logo} alt="DFS" title="DFS Home" />
              </Link>
              <button
                type="button"
                tabIndex="-1"
                className={`navbar-burger button burger ${
                  isActive ? 'is-active' : ''
                } ${
                  !isShrunk && lightText
                    ? 'has-text-light'
                    : 'has-text-black-bis'
                }`}
                aria-label="menu"
                aria-expanded="false"
                data-target="navbarBasicExample"
                onClick={() => {
                  setIsActive(!isActive)
                }}
              >
                <span aria-hidden="true" className="close-icon" />
                <span aria-hidden="true" />
                <span aria-hidden="true" className="close-icon" />
              </button>
            </div>
            <div
              className={isActive ? 'navbar-menu  is-active' : 'navbar-menu'}
            >
              <div className="navbar-start">
                {items.map(obj => (
                  <div
                    key={obj.wordpress_id}
                    className={`navbar-item ${
                      obj.title === 'Solutions' ? 'is-mega' : ''
                    } has-dropdown is-hoverable`}
                  >
                    <TopLevelNavbarItem
                      node={obj}
                      lightText={lightText}
                      isActive={isActive}
                      isShrunk={isShrunk}
                      isMega={obj.title === 'Solutions'}
                      solutions={solutions.filter(s => s.isFeatured)}
                    />
                  </div>
                ))}
                <TopLevelNavbarItem
                  node={{
                    title: 'Blog',
                    path: '/blog/',
                    slug: 'blog',
                  }}
                  lightText={lightText}
                  isShrunk={isShrunk}
                  isActive={isActive}
                  isShrunk={isShrunk}
                />
              </div>
              <div className="navbar-end">
                <div className="navbar-item" ref={searchBoxRef}>
                  {isSearchOpen ? (
                    <X
                      xlinkTitle="Close"
                      color={
                        useLightText && lightText && !isShrunk
                          ? 'white'
                          : '#363636'
                      }
                      onClick={() => setIsSearchOpen(!isSearchOpen)}
                      className="with-pointer"
                    />
                  ) : (
                    <Search
                      xlinkTitle="Search"
                      color={
                        useLightText && lightText && !isShrunk
                          ? 'white'
                          : '#363636'
                      }
                      onClick={() => setIsSearchOpen(!isSearchOpen)}
                      className="with-pointer"
                    />
                  )}
                  {isSearchOpen && (
                    <div className="navbar-search">
                      <form onSubmit={onSearch}>
                        <div className="control has-icons-left navbar-search-input">
                          <input
                            name="query"
                            className="input"
                            type="search"
                            placeholder="Search..."
                            value={searchQuery}
                            onChange={onSearchChange}
                          />
                          <span
                            role="button"
                            tabIndex="-1"
                            onKeyDown={onEnterKeydown}
                            className="icon is-medium is-left"
                            onClick={onSearch}
                          >
                            <Search />
                          </span>
                        </div>
                      </form>
                    </div>
                  )}
                </div>
                <div className="navbar-item">
                  <Link to="/contact/" title="Contact Us">
                    <button
                      type="button"
                      tabIndex="-1"
                      className="button with-animation is-rounded has-background-primary has-text-white"
                    >
                      <strong>Contact Us</strong>
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </nav>
      )}
    />
  )
}

Navbar.propTypes = {
  lightText: PropTypes.bool,
}

export default Navbar
