import React from 'react'
import FeaturedMedia from './FeaturedMedia'

const Hero = ({
  title,
  subtitle,
  featuredMedia = null,
  className,
  hrWidth = 150,
  gatsbyImage = true,
}) =>
  featuredMedia && gatsbyImage ? (
    <FeaturedMedia featuredMedia={featuredMedia} className={className}>
      <div className="container">
        <div className="hero-body is-flex is-vcentered fd-c align-items-center">
          <h1 className="title semibold has-text-white is-marginless ta-c is-size-3-mobile is-size-2-tablet is-size-1-widescreen">
            {title}
          </h1>
          {subtitle && (
            <>
              <hr width={hrWidth} />
              <p className="subtitle has-text-white ta-c is-size-5-mobile is-size-5-tablet is-size-4-widescreen">
                {subtitle}
              </p>
            </>
          )}
        </div>
      </div>
    </FeaturedMedia>
  ) : !gatsbyImage && featuredMedia ? (
    <FeaturedMedia wordpressMedia={featuredMedia} className={className}>
      <div className="container">
        <div className="hero-body is-flex is-vcentered fd-c align-items-center">
          <h1 className="title has-text-white is-marginless ta-c is-size-3-mobile is-size-2-tablet is-size-1-widescreen">
            {title}
          </h1>
          <hr width={hrWidth} />
          <p className="subtitle has-text-white ta-c is-size-5-mobile is-size-5-tablet is-size-4-widescreen">
            {subtitle}
          </p>
        </div>
      </div>
    </FeaturedMedia>
  ) : (
    <section className="hero has-gradient-red-white is-bold">
      <div className="full-width-image-container-dark">
        <div className="container">
          <div className="hero-body is-flex is-vcentered fd-c align-items-center">
            <h2 className="title has-text-white ta-c is-size-3-mobile is-size-2-tablet is-size-1-widescreen">
              {title}
            </h2>
            <hr width={hrWidth} />
            <p className="subtitle has-text-white ta-c is-size-5-mobile is-size-5-tablet is-size-4-widescreen">
              {subtitle}
            </p>
          </div>
        </div>
      </div>
    </section>
  )

export default Hero
